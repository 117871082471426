<script setup>
import { ref } from "vue";
import "@/assets/scss/_antd-form.scss";
import { Form, Input, Modal } from "ant-design-vue";
const AForm = Form;
const AFormItem = Form.Item;
const AInput = Input;
const ATextarea = Input.TextArea;
const AModal = Modal;

const processes = [
  "Gửi tài liệu tiêu chuẩn, bảng hỏi",
  "Tìm hiểu hiện trạng",
  "Trình bày giải pháp",
  "Lên phương án đề xuất & Khái toán",
  "Gửi bản báo giá",
  "Chốt hợp đồng & triển khai",
];

const formState = reactive({
  source: 3,
  status: 3,
  name: "",
  phonenumber: "",
  email: "",
  title: "",
  description: "",
});

const rules = {
  name: { required: true, message: "Tên không được để trống" },
  phonenumber: { required: true, message: "Số điện thoại không được để trống" },
  email: [
    { required: true, message: "Email không được để trống" },
    { type: "email", message: "Email không đúng định dạng", trigger: "blur" },
  ],
  title: { required: true, message: "Chức vụ không được để trống" },
  description: { required: true, message: "Ghi chú không được để trống" },
};

const loading = ref(false);
const formRef = ref();
const visibleResultModal = ref(false);

const onSubmit = async () => {
  loading.value = true;
  const formData = new FormData();

  for (const key in formState) {
    formData.append(key, formState[key]);
  }

  try {
    const response = await $fetch("https://crm.miraway.vn/api/leads", {
      method: "POST",
      headers: {
        authtoken:
          "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1c2VyIjoibWt0IiwibmFtZSI6IkZvcm0gZGF0YSAwNTA5MjMiLCJwYXNzd29yZCI6bnVsbCwiQVBJX1RJTUUiOjE2OTM5MDUxOTN9.IuSrxDvE8B4ZL5iLhR950TK8OAAB4ncadZter7WU6Es",
      },
      body: formData,
    });
    if (response.status) {
      loading.value = false;
      formRef.value.resetFields();
      visibleResultModal.value = true;
    }
  } catch (error) {
    loading.value = false;
    console.log(error);
  }
};
</script>

<template>
  <section class="2xl:container">
    <div class="lg:flex gap-8 justify-center">
      <div class="lg:w-8/12 max-lg:mt-6 bg-white rounded-[24px] sm:shadow-[0_12px_32px_0_rgba(55,129,189,0.10)] sm:p-10">
        <h5>Liên hệ</h5>
        <div class="mt-4">
          Mọi thông tin chi tiết về chính sách hợp tác kinh doanh, các gói cước sản phẩm, dịch vụ, quý khách hàng vui
          lòng liên hệ hotline.
        </div>

        <button class="bg-[#02CA16] flex items-center justify-center gap-5 border-none w-full mt-5 p-6">
          <nuxt-picture src="/images/icons/ic-phone.png" loading="lazy" :img-attrs="{ alt: 'phone' }" />

          <a href="tel:0981 350 960" class="text-white">0981 350 960</a>
        </button>

        <h5 class="my-5">Để lại thông tin ngay để nhận tư vấn miễn phí</h5>

        <a-form ref="formRef" class="mt-[40px]" layout="vertical" :model="formState" :rules="rules" @finish="onSubmit">
          <div class="flex max-sm:flex-col gap-x-4">
            <a-form-item label="Họ và tên" class="flex-1" name="name">
              <a-input v-model:value="formState.name" placeholder="Nhập họ và tên"></a-input>
            </a-form-item>

            <a-form-item label="Số điện thoại" class="flex-1" name="phonenumber">
              <a-input v-model:value="formState.phonenumber" placeholder="Nhập số điện thoại"></a-input>
            </a-form-item>
          </div>

          <div class="flex max-sm:flex-col gap-x-4">
            <a-form-item label="Email" class="flex-1" name="email">
              <a-input v-model:value="formState.email" placeholder="Nhập email"></a-input>
            </a-form-item>

            <a-form-item label="Chức vụ" class="flex-1" name="title">
              <a-input v-model:value="formState.title" placeholder="Nhập chức vụ"></a-input>
            </a-form-item>
          </div>

          <a-form-item label="Lời nhắn" name="description">
            <a-textarea v-model:value="formState.description" placeholder="Để lại lời nhắn" :rows="5"></a-textarea>
          </a-form-item>

          <a-form-item class="text-right">
            <button class="primary" type="submit" :disabled="loading">Gửi tin nhắn</button>
          </a-form-item>
        </a-form>

        <a-modal :visible="visibleResultModal" :footer="null" :closable="false">
          <h6>Cám ơn bạn đã quan tâm giải pháp của Miraway.</h6>
          <div class="mt-2">Chúng tôi sẽ liên hệ bạn thong thời gian sớm nhất!</div>

          <div class="text-center mt-6">
            <button class="primary" @click="visibleResultModal = false">Đóng</button>
          </div>
        </a-modal>
      </div>
    </div>
  </section>
</template>
